exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apple-tsx": () => import("./../../../src/pages/apple.tsx" /* webpackChunkName: "component---src-pages-apple-tsx" */),
  "component---src-pages-delete-tsx": () => import("./../../../src/pages/delete.tsx" /* webpackChunkName: "component---src-pages-delete-tsx" */),
  "component---src-pages-discord-tsx": () => import("./../../../src/pages/discord.tsx" /* webpackChunkName: "component---src-pages-discord-tsx" */),
  "component---src-pages-epic-tsx": () => import("./../../../src/pages/epic.tsx" /* webpackChunkName: "component---src-pages-epic-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-googleplay-tsx": () => import("./../../../src/pages/googleplay.tsx" /* webpackChunkName: "component---src-pages-googleplay-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-stats-tsx": () => import("./../../../src/pages/stats.tsx" /* webpackChunkName: "component---src-pages-stats-tsx" */),
  "component---src-pages-tos-tsx": () => import("./../../../src/pages/tos.tsx" /* webpackChunkName: "component---src-pages-tos-tsx" */)
}

